/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProblemDetails,
  ProductCardApiModel,
  ProductDetailApiModel,
  StockApiModel,
} from '../models';
import {
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProductCardApiModelFromJSON,
    ProductCardApiModelToJSON,
    ProductDetailApiModelFromJSON,
    ProductDetailApiModelToJSON,
    StockApiModelFromJSON,
    StockApiModelToJSON,
} from '../models';

export interface ProductsGetSkuInputGetRequest {
    input: string;
}

export interface ProductsSkuGetRequest {
    sku: string;
}

export interface ProductsSkuStockGetRequest {
    sku: string;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async productsGetSkuInputGetRaw(requestParameters: ProductsGetSkuInputGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.input === null || requestParameters.input === undefined) {
            throw new runtime.RequiredError('input','Required parameter requestParameters.input was null or undefined when calling productsGetSkuInputGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Products/GetSku/{input}`.replace(`{${"input"}}`, encodeURIComponent(String(requestParameters.input))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async productsGetSkuInputGet(requestParameters: ProductsGetSkuInputGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.productsGetSkuInputGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsHomeInterestedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductCardApiModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Products/HomeInterested`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductCardApiModelFromJSON));
    }

    /**
     */
    async productsHomeInterestedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductCardApiModel>> {
        const response = await this.productsHomeInterestedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async productsRecentlyOrderedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductCardApiModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Products/RecentlyOrdered`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductCardApiModelFromJSON));
    }

    /**
     */
    async productsRecentlyOrderedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductCardApiModel>> {
        const response = await this.productsRecentlyOrderedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async productsSkuGetRaw(requestParameters: ProductsSkuGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDetailApiModel>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling productsSkuGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Products/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDetailApiModelFromJSON(jsonValue));
    }

    /**
     */
    async productsSkuGet(requestParameters: ProductsSkuGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDetailApiModel> {
        const response = await this.productsSkuGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async productsSkuStockGetRaw(requestParameters: ProductsSkuStockGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StockApiModel>>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling productsSkuStockGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Products/{sku}/Stock`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StockApiModelFromJSON));
    }

    /**
     */
    async productsSkuStockGet(requestParameters: ProductsSkuStockGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StockApiModel>> {
        const response = await this.productsSkuStockGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
