/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.27
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductDownloadApiModel
 */
export interface ProductDownloadApiModel {
    /**
     * 
     * @type {string}
     * @memberof ProductDownloadApiModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDownloadApiModel
     */
    url?: string | null;
}

/**
 * Check if a given object implements the ProductDownloadApiModel interface.
 */
export function instanceOfProductDownloadApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductDownloadApiModelFromJSON(json: any): ProductDownloadApiModel {
    return ProductDownloadApiModelFromJSONTyped(json, false);
}

export function ProductDownloadApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDownloadApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ProductDownloadApiModelToJSON(value?: ProductDownloadApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'url': value.url,
    };
}

